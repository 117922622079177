<template>
  <div>
    <div>
      <ul>
        <li>Libéllé: <b>{{ data.name }}</b></li>
      </ul>
    </div>
    <hr />
    <!--<small>
      Ajouté le {{ this.$coddyger.cdgDateFormat(data.atl_date, true) }} - 
      Dernière mise à jour {{ this.$coddyger.cdgDateFormat(data.last_updated, true) }}
    </small>-->
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import payloads from '../payloads.js'

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      states: payloads.states
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const niceAction = payload => {
      if(payload == 'write') return 'Ecriture'
      if(payload == 'read') return 'Lecture'
      if(payload == 'manage') return 'Gestion complète'
    }

    const niceSubject = payload => {
      if(payload == 'all') return 'Tous les modules'

      return payload
    }

    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }

    return {
      postStatusVariantAndIcon,
      niceAction,
      niceSubject,
    }
  },
})
</script>
