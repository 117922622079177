<template>
  <div>
    <div></div>
    <b-form @submit.prevent="save">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col>
              <!-- TITLE -->
              <b-form-group label="* Libéllé" label-for="title">
                <b-form-input
                  id="title"
                  v-model="formdata.fields.name"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- submit and reset -->
      <b-row>
        <b-col lg="12"><hr /></b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
            style="float: right"
            :disabled="disableButtonOnLoading"
          >
            <span v-if="!isLoading">Enregistrer</span>
            <span v-if="isLoading">
              <b-spinner small type="grow" />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormInput,
  BFormSelect,
  BTable,
  BForm,
  BCardText,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import payloads from '../payloads.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SampleStore from '@/store/app/sample'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormInput,
    BFormSelect,
    BTable,
    BForm,
    BCardText,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup, 

    vSelect,
    Cleave,
    
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
    api: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      isLoading: false,
      formdata: {
        fields: {
          slug: null,
          name: '',
        },
        payloads: {
          states: payloads.states,
        },
        options: {
          number: {
            numeral: true,
          },
        },
      },
    }
  },
  computed: {
    buildRates() {
      let data = []

      for(let i = 10;i<= 100;i++) {
        data.push(i)
      }

      return data
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
  },

  methods: {
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      let fields = this.formdata.fields;

      if(this.$coddyger.string.is_empty(fields.name)) {
        this.swalError('Erreur', this.$coddyger.buildRequiredMessage('Libéllé'), 'error')
      } else {
        this.isLoading = false
        const a = Object.assign({}, fields); // Create a copy of the object

        this.sendToStore(a)
      }
    },
    sendToStore(fields) {
      let globalData = {}
      const formData = {
        id: fields.slug,
        name: fields.name,
      }

      // console.log(formData)
      // return false

      globalData = {
        formdata: formData,
        path: ((fields.slug !== null && fields.slug !== '') ? this.api.paths.edit + fields.slug : this.api.paths.save),
      }

      this.isLoading = true

      if(fields.slug !== null && fields.slug !== '') {
        // EDIT
        this.$store.dispatch('SampleStore/edit', globalData).then((a) => {
          this.isLoading = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Info',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Votre étendue territoriale a été modifiée avec succès !',
            },
          })
        }).catch((error) => {
          this.isLoading = false
          console.log(error)

          this.swalError('Erreur', error.detail, 'error')
        })
      } else {
        // SAVE
        this.$store.dispatch('SampleStore/save', globalData).then((a) => {
          this.isLoading = false
          this.swalError('Succès', "Votre étendue territoriale a été créé avec succès !", 'success')

          // Reset fields value
          this.$coddyger.resetObjectValues(this.formdata.fields);
        }).catch((error) => {
          this.isLoading = false
          console.log(error)
          
          this.swalError('Erreur', error.detail, 'error')
        })
      }
    },
  },
  created() {
    let data = this.data;

    if(this.$coddyger.object.is_empty(data) == false) {
      // Initialise l'identifiant unique par defaut avec la valeur de l'élément sélectionné
      this.formdata.fields.slug = data.id

      // Initialise tous les champs avec la valeur de l'élément sélectionné
      this.formdata.fields.name = data.name
    }
  },
}
</script>
